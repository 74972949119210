import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import axios from "axios";
import ReactApexChart from "react-apexcharts";

interface BookedContractedData {
  month: number;
  strMonth: string;
  booked: number | null;
  contracted: number | null;
  percentage: number | null;
}

const BookedContractedReport = () => {
  const [data, setData] = useState<BookedContractedData[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [chartOptions, setChartOptions] = useState<any>(null);
  const [chartSeries, setChartSeries] = useState<any>([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/booked-contracted");
      const results: BookedContractedData[] = response.data.map((item: any) => {
        const booked = item.booked ? parseFloat(item.booked) : null;
        const contracted = item.contracted ? parseFloat(item.contracted) : null;
        let percentage: number | null = null;
        if (booked && contracted !== null) {
          percentage = parseFloat(((contracted / booked) * 100).toFixed(1));
        }
        return {
          month: item.month,
          strMonth: item.strMonth,
          booked,
          contracted,
          percentage,
        };
      });
      setData(results);
      setLoading(false);
    } catch (err: any) {
      console.error("Error fetching data:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Compute totals and update chart options when data is loaded.
  useEffect(() => {
    if (data) {
      const totalBooked = data.reduce(
        (sum, item) => sum + (item.booked || 0),
        0
      );
      const totalContracted = data.reduce(
        (sum, item) => sum + (item.contracted || 0),
        0
      );
      const totalOverall = totalBooked + totalContracted;
      setChartOptions({
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val: number) {
            return "$" + Math.round(val).toLocaleString();
          },
          dropShadow: { enabled: false },
          style: { fontSize: "14px", fontWeight: "400", colors: ["#000000"] },
        },
        xaxis: {
          categories: ["Booked", "Contracted", "Total"],
        },
        yaxis: {
          labels: {
            formatter: (value: number) => {
              if (value >= 1_000_000) {
                return "$" + (value / 1_000_000).toFixed(1) + "M"; // Converts to 1M, 2.5M, etc.
              } else if (value >= 1_000) {
                return "$" + (value / 1_000).toFixed(1) + "K"; // Converts to 1K, 2.5K, etc.
              }
              return value.toString(); // Keeps smaller values unchanged
            },
          },
        },
      });
      setChartSeries([
        {
          name: "Amount",
          data: [totalBooked, totalContracted, totalOverall],
        },
      ]);
    }
  }, [data]);

  const totalBooked = data
    ? data.reduce((sum, item) => sum + (item.booked || 0), 0)
    : 0;
  const totalContracted = data
    ? data.reduce((sum, item) => sum + (item.contracted || 0), 0)
    : 0;
  const totalOverall = totalBooked + totalContracted;

  if (loading) {
    return <div>Loading Booked and Contracted Report...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Col xs='12'>
      <Row>
        <Col xs='12'>
          <h3>Booked and Contracted Report</h3>
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          <h5>Total Summary</h5>
          <p>Booked: ${totalBooked.toLocaleString()}</p>
          <p>Contracted: ${totalContracted.toLocaleString()}</p>
          <p>Total: ${totalOverall.toLocaleString()}</p>
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          {chartOptions && chartSeries.length > 0 && (
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type='bar'
              height={350}
            />
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default BookedContractedReport;
