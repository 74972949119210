import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import {
  FaChartBar,
  FaBriefcase,
  FaWpforms,
  FaFileInvoiceDollar,
  FaMedal,
} from "react-icons/fa";
import Header from "../../../Layout/Header/Header";

const ContainerDefault = () => {
  const navigate = useNavigate();

  return (
    <Container fluid className='dashboard-container'>
      <Header />
      <Row>
        <Col xs='12'>
          <h2 className='dashboard-title'>Dashboard</h2>
        </Col>
      </Row>
      <Row>
        <Col xs='12' md='6'>
          <Card className='dashboard-card'>
            <CardBody>
              <h3>Reports</h3>
              <Card className='inner-card'>
                <CardBody>
                  <Card className='link-card'>
                    <CardBody>
                      <a
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/booked-contracted-report");
                        }}
                      >
                        <FaFileInvoiceDollar className='icon' /> Booked and
                        Contracted
                      </a>
                    </CardBody>
                  </Card>
                  <Card className='link-card'>
                    <CardBody>
                      <a
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/utilization-report");
                        }}
                      >
                        <FaChartBar className='icon' /> Utilization Report
                      </a>
                    </CardBody>
                  </Card>
                  <Card className='link-card'>
                    <CardBody>
                      <a
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(
                            "/jobs-report?filterStatus=Validated&reportTitle=Open%20Jobs%20Report%20-%20Recruiting%20Needs%20(Validated%20and%20Interviewing)"
                          );
                        }}
                      >
                        <FaBriefcase className='icon' /> Open Jobs Report -
                        Recruiting Needs (Validated and Interviewing)
                      </a>
                    </CardBody>
                  </Card>
                  <Card className='link-card'>
                    <CardBody>
                      <a
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(
                            "/jobs-report?filterStatus=Top%20Level&reportTitle=Open%20Jobs%20Report%20-%20Top%20of%20Funnel%20(Prospecting%20and%20Identified)"
                          );
                        }}
                      >
                        <FaBriefcase className='icon' /> Open Jobs Report - Top
                        of Funnel (Prospecting and Identified)
                      </a>
                    </CardBody>
                  </Card>

                  <Card className='link-card'>
                    <CardBody>
                      <a
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/pipeline-report");
                        }}
                      >
                        <FaChartBar className='icon' /> Pipeline Report
                      </a>
                    </CardBody>
                  </Card>
                  <Card className='link-card'>
                    <CardBody>
                      <a
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/leaderboard");
                        }}
                      >
                        <FaMedal className='icon' /> Sales Team Leaderboard
                      </a>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </Col>
        <Col xs='12' md='6'>
          <Card className='dashboard-card'>
            <CardBody>
              <h3>Forms</h3>
              <Card className='inner-card'>
                <CardBody>
                  <Card className='link-card'>
                    <CardBody>
                      <a
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/screening");
                        }}
                      >
                        <FaWpforms className='icon' /> Screenings
                      </a>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ContainerDefault;
