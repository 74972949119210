import React, { useState, useEffect } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { H3, H5 } from "../../../../AbstractElements";
import axios from "axios";

interface Candidate {
  id: number;
  firstName: string;
  lastName: string;
}

interface Submission {
  id: number;
  candidate: Candidate;
  comments: string | null;
}

interface OpenJobData {
  id: number;
  title: string;
  owner: { id: number; firstName: string; lastName: string };
  clientCorporation: { id: number; name: string };
  submissions?: { candidates?: { total: number; data: Submission[] } };
  description: string;
  status: string;
  customFloat3: number;
  notes: string;
}
interface StatusGroup {
  status: string;
  totalCustomFloat3: number;
  jobCount: number;
}
interface OpenJobsReportProps {
  filterStatus?: string; // Optional prop for filtering by status
  reportTitle?: string; // Optional prop for custom report title
}

const OpenJobsReport: React.FC<OpenJobsReportProps> = ({
  filterStatus,
  reportTitle,
}) => {
  const [data, setData] = useState<OpenJobData[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof OpenJobData;
    direction: "asc" | "desc";
  } | null>(null);

  const formatterUSD = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const fetchData = async () => {
    setLoading(true);
    let query = "";
    if (filterStatus) {
      switch (filterStatus) {
        case "Top Level":
          query = `query=isDeleted:0 AND isOpen:1 AND (status:"Prospecting" OR status:"Identified"`;
          break;
        case "Validated":
          query = `query=isDeleted:0 AND isOpen:1 AND (status:"Validated" OR status:"Interviewing"`;
          break;
        default:
          query = `query=isDeleted:0 AND isOpen:1`;
          break;
      }
    }
    try {
      const response = await axios.get(`/open-jobs?${query})`);

      // Sort jobs by owner first name
      let sortedData = response.data.sort((a: OpenJobData, b: OpenJobData) =>
        a.owner.firstName.localeCompare(b.owner.firstName)
      );
      // Apply status filter if provided

      setData(sortedData);
      setLoading(false);
    } catch (err: any) {
      console.error("Error fetching data:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filterStatus]);

  const sortData = (key: keyof OpenJobData) => {
    if (!data) return;

    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }

    const sortedData = () => {
      if (!data || !sortConfig?.key) return data || [];

      return [...data].sort((a, b) => {
        const key = sortConfig.key as keyof OpenJobData;
        const aValue =
          key === "clientCorporation"
            ? a.clientCorporation.name
            : key === "owner"
            ? `${a.owner.firstName} ${a.owner.lastName}`
            : a[key];

        const bValue =
          key === "clientCorporation"
            ? b.clientCorporation.name
            : key === "owner"
            ? `${b.owner.firstName} ${b.owner.lastName}`
            : b[key];

        if (aValue === undefined || bValue === undefined) return 0;
        if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
    };

    setData(sortedData);
    setSortConfig({ key, direction });
  };
  const groupByStatus = (): StatusGroup[] => {
    if (!data) return [];

    const groupsMap: { [key: string]: { total: number; count: number } } = {};

    data.forEach((job) => {
      const { status, customFloat3 } = job;
      if (groupsMap[status]) {
        groupsMap[status].total += customFloat3;
        groupsMap[status].count += 1;
      } else {
        groupsMap[status] = { total: customFloat3, count: 1 };
      }
    });

    // Convert the groupsMap to an array of StatusGroup
    const statusGroups: StatusGroup[] = Object.entries(groupsMap).map(
      ([status, { total, count }]) => ({
        status,
        totalCustomFloat3: total,
        jobCount: count,
      })
    );

    // Sort the groups in descending order based on totalCustomFloat3
    statusGroups.sort((a, b) => b.totalCustomFloat3 - a.totalCustomFloat3);

    return statusGroups;
  };

  // Get the grouped data
  const statusGroups: StatusGroup[] = groupByStatus();

  if (loading) {
    return (
      <>
        <div>Pulling fresh data from Bullhorn.</div>
        <div>Please wait 30 seconds… Make IT Happen today!</div>
        <Spinner color='primary' />
      </>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Col xs='12'>
      <style>{`
        .open-jobs-list {
          width: 100%;
          border-collapse: collapse;
          margin: 20px 0;
          font-size: 1rem;
          text-align: left;
        }
        .open-jobs-list th, .open-jobs-list td {
          padding: 12px 15px;
          border: 1px solid #ddd;
        }
        .open-jobs-list th {
          background-color: #f4f4f4;
          font-weight: bold;
        }
        .open-jobs-list .even-row {
          background-color: #f9f9f9;
        }
        .open-jobs-list .odd-row {
          background-color: #fff;
        }
        .open-jobs-list tr:hover {
          background-color: #f1f1f1;
        }
        .candidate-list {
          padding-left: 20px;
          list-style: none;
          margin: 10px 0;
        }
        .candidate-list li {
          margin-bottom: 5px;
        }
        .status-groups-table {
          width: 100%;
          border-collapse: collapse;
          margin: 20px 0;
          font-size: 1rem;
          text-align: left;
        }
        .status-groups-table th, .status-groups-table td {
          padding: 12px 15px;
          border: 1px solid #ddd;
        }
        .status-groups-table th {
          background-color: #e2e2e2;
          font-weight: bold;
        }
        .no-submissions {
            color: gray;
        }
      `}</style>
      <Row>
        <Col xs='12'>
          <H3 className='font-primary'>{reportTitle}</H3>
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          <H5 className='font-primary'>Total</H5>
          <p>Total Open Jobs: {data?.length || 0}</p>
          {statusGroups.map((group) => (
            <p key={group.status}>
              {group.status} Projected Revenue:{" "}
              {formatterUSD.format(group.totalCustomFloat3)}
            </p>
          ))}
        </Col>
      </Row>

      <Row>
        <Col xs='12'>
          <table className='open-jobs-list'>
            <thead>
              <tr>
                <th>Job Name</th>
                <th onClick={() => sortData("id")}>
                  Job Number (Click to view)
                </th>
                <th onClick={() => sortData("clientCorporation")}>Client</th>
                <th onClick={() => sortData("owner")}>Account Manager</th>
                <th onClick={() => sortData("status")}>Status</th>
                <th onClick={() => sortData("customFloat3")}>
                  Projected Revenue
                </th>
                {filterStatus === "Validated" ? (
                  <th>Submissions (Click to view Candidate)</th>
                ) : (
                  ""
                )}
                <th>Last Note</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((job, index) => (
                <tr
                  key={job.id}
                  className={index % 2 === 0 ? "even-row" : "odd-row"}
                >
                  <td>{job.title}</td>
                  <td>
                    <a
                      target='_blank'
                      href={`https://cls44.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=JobOrder&id=${job.id}`}
                      aria-label={`Job Order ${job.id}`}
                      rel='noreferrer'
                    >
                      {job.id}
                    </a>
                  </td>
                  <td>{job.clientCorporation.name}</td>
                  <td>
                    {job.owner.firstName} {job.owner.lastName}
                  </td>
                  <td>{job.status}</td>
                  <td>{formatterUSD.format(job.customFloat3)}</td>

                  {filterStatus === "Validated" ? (
                    <td>
                      <ul className='candidate-list'>
                        {(() => {
                          const candidatesData =
                            job.submissions?.candidates?.data;
                          const normalizedCandidates = Array.isArray(
                            candidatesData
                          )
                            ? candidatesData
                            : candidatesData
                            ? [candidatesData]
                            : [];

                          if (normalizedCandidates.length === 0) {
                            return (
                              <li className='no-submissions'>
                                No candidates available
                              </li>
                            );
                          }

                          return normalizedCandidates.map((submission) => (
                            <li key={submission.candidate.id}>
                              <a
                                target='_blank'
                                href={`https://cls44.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=${submission.candidate.id}`}
                                aria-label={`Candidate ${submission.candidate.id}`}
                                rel='noreferrer'
                              >
                                {submission.candidate.firstName}{" "}
                                {submission.candidate.lastName}
                              </a>
                            </li>
                          ));
                        })()}
                      </ul>
                    </td>
                  ) : (
                    ""
                  )}
                  <td>{job.notes || "No Update Note Available"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </Col>
  );
};

export default OpenJobsReport;
