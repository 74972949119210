import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Table,
  Spinner,
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import axios from "axios";

export const statusOrder = [
  "Prospecting",
  "Identified",
  "Validated",
  "Submitted",
  "Interviewing",
  "Client Review",
  "Verbal Won - Contracting",
];

const additionalStatusCategories = ["Placed", "Lost"];

interface PipeLineConversionData {
  owner_id: number;
  status: string;
  job_count: number;
  projected_revenue: number;
  avg_time_diff: number;
}

interface PipeLineReportData {
  id: number;
  clientCorporation: {
    id: number;
    name: string;
  };
  title: string;
  owner: {
    id: number;
    firstName: string;
    lastName: string;
  };
  status: string;
  customFloat3: number;
  dateAdded: string;
  notes: string;
  _score: number;
}

interface ConversionInfo {
  jobCount: number;
  revenue: number;
  conversion: string;
  avgTimeDiff: number;
}

const PipeLineReport = () => {
  const [data, setData] = useState<PipeLineReportData[]>([]);
  const [conversionData, setConversionData] = useState<
    PipeLineConversionData[]
  >([]);
  const [filteredData, setFilteredData] = useState<PipeLineReportData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [chartData, setChartData] = useState<ApexOptions | null>(null);
  const [owners, setOwners] = useState<{ id: number | "All"; name: string }[]>(
    []
  );
  const [selectedOwner, setSelectedOwner] = useState<number | "All">("All");

  // Default to "Pipeline" to display all jobs initially
  const [selectedStatus, setSelectedStatus] = useState<string | null>(
    "Pipeline"
  );
  const [statusJobs, setStatusJobs] = useState<PipeLineReportData[]>([]);
  const [totals, setTotals] = useState<Record<string, number>>({});
  const [totalPipelineValue, setTotalPipelineValue] = useState(0);

  // Store conversion rates as an object keyed by phase
  const [conversionRates, setConversionRates] = useState<
    Record<string, ConversionInfo>
  >({});

  // Sort pipeline data for consistent ordering in the funnel chart
  const sortPipelineData = (
    pipeline: PipeLineReportData[]
  ): PipeLineReportData[] => {
    return pipeline.sort(
      (a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
    );
  };

  // Group totals by status (used for the funnel chart)
  const groupTotalsByStatus = (
    pipeline: PipeLineReportData[]
  ): Record<string, number> => {
    const totals: Record<string, number> = [
      ...statusOrder,
      ...additionalStatusCategories,
    ].reduce((acc, status) => ({ ...acc, [status]: 0 }), {});
    pipeline.forEach((item) => {
      if (totals.hasOwnProperty(item.status)) {
        totals[item.status] += item.customFloat3;
      }
    });
    return totals;
  };

  // Update the funnel chart based on the filtered results
  const updateChart = (filteredResults: PipeLineReportData[]) => {
    const newTotals = groupTotalsByStatus(filteredResults);
    setTotals(newTotals);

    const pipelineValue = statusOrder.reduce(
      (acc, status) => acc + (newTotals[status] || 0),
      0
    );
    setTotalPipelineValue(pipelineValue);

    setChartData({
      series: [
        {
          name: "Total Value",
          data: statusOrder.map((status) => newTotals[status]),
        },
      ],
      chart: {
        type: "bar",
        height: 500,
        toolbar: { show: true },
        events: {
          dataPointSelection: (_, __, config) => {
            const clickedStatus = statusOrder[config.dataPointIndex];
            setSelectedStatus(clickedStatus);
            setStatusJobs(
              filteredResults.filter((job) => job.status === clickedStatus)
            );
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          distributed: true,
          barHeight: "80%",
          isFunnel: true,
        },
      },
      colors: [
        "#0094ba",
        "#2481aa",
        "#476d9b",
        "#6b5a8b",
        "#8f467b",
        "#b3336b",
        "#d61f5c",
        "#fa0c4c",
      ],
      dataLabels: {
        enabled: true,
        formatter: (val, opts) =>
          `${statusOrder[opts.dataPointIndex]} - $${val.toLocaleString()}`,
        dropShadow: { enabled: false },
        style: { fontSize: "14px", fontWeight: "400", colors: ["#000000"] },
      },
      tooltip: { enabled: false },
      title: { text: "Pipeline Report", align: "center" },
      xaxis: { categories: statusOrder, labels: { show: false } },
      yaxis: {
        labels: {
          show: true,
          formatter: (_, index) => statusOrder[index],
          style: { fontSize: "14px", fontWeight: "bold", colors: ["#000000"] },
        },
      },
    });
  };

  // Fetch pipeline and conversion data
  const fetchData = async () => {
    setLoading(true);
    try {
      const pipelineRes = await axios.get("/pipeline");
      const sorted = sortPipelineData(pipelineRes.data);

      // Parse conversion data so that customFloat3 is a number
      const convRes = await axios.get("/pipeline-conversion");

      const conversionResults = convRes.data.map((item: any) => ({
        ...item,
        projected_revenue: item.projected_revenue
          ? parseFloat(item.projected_revenue)
          : 0,
        avg_time_diff: item.avg_time_diff
          ? parseFloat(item.avg_time_diff)
          : null,
      }));
      setConversionData(conversionResults);

      const uniqueOwners = Array.from(
        new Map(
          sorted.map((item) => [
            item.owner.id,
            {
              id: item.owner.id,
              name: `${item.owner.firstName} ${item.owner.lastName}`,
            },
          ])
        ).values()
      );
      setOwners([{ id: "All", name: "Sales Team" }, ...uniqueOwners]);

      setData(sorted);
      setFilteredData(sorted);
      updateChart(sorted);
      setStatusJobs(sorted); // Display all jobs by default

      setLoading(false);
    } catch (err: any) {
      console.error("Error fetching data:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  // Compute conversion rates showing both counts and revenue
  const computeConversionRates = () => {
    const filteredConversions =
      selectedOwner === "All"
        ? conversionData
        : conversionData.filter((item) => item.owner_id === selectedOwner);

    const aggregated = filteredConversions.reduce((acc, item) => {
      if (!acc[item.status]) {
        acc[item.status] = {
          jobCount: 0,
          revenue: 0,
          avgTimeDiff: 0,
          avgTimeDiffCount: 0,
        };
      }
      acc[item.status].jobCount += item.job_count;
      acc[item.status].revenue += item.projected_revenue;
      if (item.avg_time_diff > 0) {
        acc[item.status].avgTimeDiff += item.avg_time_diff;
        acc[item.status].avgTimeDiffCount += 1;
      }

      return acc;
    }, {} as Record<string, { jobCount: number; revenue: number; avgTimeDiff: number; avgTimeDiffCount: number }>);

    const rates: Record<string, ConversionInfo> = {};

    // For each phase in statusOrder, compute conversion relative to the previous phase's revenue
    statusOrder.forEach((status, index) => {
      const jobCount = aggregated[status]?.jobCount ?? 0;
      const revenue = aggregated[status]?.revenue ?? 0;
      let conversion = "";
      if (index === 0) {
        conversion = "";
      } else {
        const prevStatus = statusOrder[index - 1];
        const prevRevenue = aggregated[prevStatus]?.revenue ?? 0;
        conversion =
          prevRevenue > 0
            ? ((revenue / prevRevenue) * 100).toFixed(1) + "%"
            : "N/A";
      }
      rates[status] = {
        jobCount,
        revenue,
        conversion,
        avgTimeDiff:
          aggregated[status]?.avgTimeDiffCount > 0
            ? aggregated[status].avgTimeDiff /
              aggregated[status].avgTimeDiffCount
            : 0,
      };
    });

    // For "Placed" and "Lost", conversion is compared to "Prospecting"
    const prospectingRevenue = aggregated["Prospecting"]?.revenue ?? 0;
    additionalStatusCategories.forEach((status) => {
      const jobCount = aggregated[status]?.jobCount ?? 0;
      const revenue = aggregated[status]?.revenue ?? 0;
      const conversion =
        prospectingRevenue > 0
          ? ((revenue / prospectingRevenue) * 100).toFixed(1) + "%"
          : "N/A";
      rates[status] = {
        jobCount,
        revenue,
        conversion,
        avgTimeDiff:
          aggregated[status]?.avgTimeDiffCount > 0
            ? aggregated[status].avgTimeDiff /
              aggregated[status].avgTimeDiffCount
            : 0,
      };
    });

    return rates;
  };

  useEffect(() => {
    if (conversionData && conversionData.length > 0) {
      const newRates = computeConversionRates();
      setConversionRates(newRates);
    }
  }, [conversionData, selectedOwner]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedOwner === "All") {
      setFilteredData(data);
      updateChart(data);
      if (selectedStatus === "Pipeline") {
        setStatusJobs(data);
      }
    } else {
      const filteredResults = data.filter(
        (item) => item.owner.id === selectedOwner
      );
      setFilteredData(filteredResults);
      updateChart(filteredResults);
      if (selectedStatus === "Pipeline") {
        setStatusJobs(filteredResults);
      }
    }
  }, [selectedOwner, data]);

  const handleStatusClick = (status: string) => {
    setSelectedStatus(status);
    setStatusJobs(filteredData.filter((job) => job.status === status));
  };

  const orderedStatuses = [...statusOrder, ...additionalStatusCategories];

  if (loading) {
    return (
      <>
        <div>Pulling fresh data from Bullhorn.</div>
        <div>Please wait 30 seconds… Make IT Happen today!</div>
        <Spinner color='primary' />
      </>
    );
  }
  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Card>
      <CardHeader>
        <div>Total Pipeline Value: ${totalPipelineValue.toLocaleString()}</div>
        <div style={{ marginTop: "10px" }}>
          <strong>Conversion Rates:</strong>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "5px",
              textAlign: "left",
            }}
          >
            <thead>
              <tr>
                <th style={{ border: "1px solid #ddd", padding: "4px" }}>
                  Phase
                </th>
                <th style={{ border: "1px solid #ddd", padding: "4px" }}>
                  Count
                </th>
                <th style={{ border: "1px solid #ddd", padding: "4px" }}>
                  $ Value
                </th>
                <th style={{ border: "1px solid #ddd", padding: "4px" }}>
                  Conversion
                </th>
              </tr>
            </thead>
            <tbody>
              {orderedStatuses.map((status) => {
                const phaseInfo = conversionRates[status];
                return (
                  <tr
                    key={status}
                    style={
                      status === "Placed" || status === "Lost"
                        ? { fontWeight: "bold", backgroundColor: "#f5f5f5" }
                        : {}
                    }
                  >
                    <td style={{ border: "1px solid #ddd", padding: "4px" }}>
                      {status}
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "4px" }}>
                      {phaseInfo?.jobCount.toLocaleString() ?? 0}
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "4px" }}>
                      {phaseInfo?.revenue
                        ? `$${phaseInfo.revenue.toLocaleString()}`
                        : "$0"}
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "4px" }}>
                      {phaseInfo?.conversion || ""}
                      {phaseInfo &&
                      phaseInfo.avgTimeDiff > 0 &&
                      !isNaN(phaseInfo.avgTimeDiff) ? (
                        <span style={{ color: "gray", marginLeft: "5px" }}>
                          (Avg of {Math.round(phaseInfo.avgTimeDiff)} days till
                          final status)
                        </span>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </CardHeader>
      <CardBody>
        <FormGroup>
          <Label for='ownerSelect'>Filter by Owner:</Label>
          <Input
            type='select'
            id='ownerSelect'
            value={selectedOwner}
            onChange={(e) =>
              setSelectedOwner(
                e.target.value === "All" ? "All" : parseInt(e.target.value)
              )
            }
          >
            {owners.map((owner) => (
              <option key={owner.id} value={owner.id}>
                {owner.name}
              </option>
            ))}
          </Input>
        </FormGroup>
        <ReactApexChart
          options={chartData!}
          series={chartData!.series}
          type='bar'
          height={500}
        />
        {/* <div className='totals-container' style={{ marginTop: "1rem" }}>
          <p>
            <strong
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "blue",
              }}
              onClick={() => handleStatusClick("Placed")}
            >
              Total Placed:
            </strong>{" "}
            ${totals["Placed"]?.toLocaleString() || 0}
          </p>
        </div> */}
        {selectedStatus && (
          <>
            <h5 className='mt-4'>
              {selectedStatus === "Pipeline"
                ? "All Pipeline Jobs"
                : `Jobs in "${selectedStatus}"`}
            </h5>
            <Table bordered striped>
              <thead>
                <tr>
                  <th>Job ID</th>
                  <th>Job Title</th>
                  <th>Client</th>
                  <th>Owner</th>
                  <th>Value</th>
                  <th>Date Added</th>
                  <th>Last Update Note</th>
                </tr>
              </thead>
              <tbody>
                {statusJobs.length > 0 ? (
                  statusJobs.map((job) => (
                    <tr key={job.id}>
                      <td>
                        <a
                          href={`https://cls44.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=JobOrder&id=${job.id}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          {job.id}
                        </a>
                      </td>
                      <td>{job.title}</td>
                      <td>{job.clientCorporation.name}</td>
                      <td>{`${job.owner.firstName} ${job.owner.lastName}`}</td>
                      <td>${job.customFloat3.toLocaleString()}</td>
                      <td>{new Date(job.dateAdded).toLocaleDateString()}</td>
                      <td>{job.notes || "No Update Note Available"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} className='text-center'>
                      No jobs found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default PipeLineReport;
