import React from "react";
import { Card, CardHeader, CardBody, Table } from "reactstrap";

const SalesTeamLeaderboard = () => {
  // Hardcoded data from your screenshot
  const leaderboardData = [
    {
      name: "Peter Squire",
      bookedRevenue: "$2,373,123",
      annualQuota: "$8,000,000",
      activePipeline: "$4,590,760",
      consultantsOnBilling: 0, // Update if needed
    },
    {
      name: "Maroussia Petryk",
      bookedRevenue: "$1,126,277",
      annualQuota: "$5,000,000",
      activePipeline: "$1,867,300",
      consultantsOnBilling: 0, // Update if needed
    },
    {
      name: "Kayla Webb",
      bookedRevenue: "$548,021",
      annualQuota: "$5,000,000",
      activePipeline: "$2,337,300",
      consultantsOnBilling: 0, // Update if needed
    },
    {
      name: "Abbi Haering",
      bookedRevenue: "$0",
      annualQuota: "$3,750,000",
      activePipeline: "$3,120,000",
      consultantsOnBilling: 0, // Update if needed
    },
  ];

  return (
    <Card>
      <CardHeader>Sales Team Leaderboard</CardHeader>
      <CardBody>
        <Table bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Booked Revenue</th>
              <th>Annual Quota</th>
              <th>Active Pipeline</th>
              {/* <th>Consultants on Billing</th> */}
            </tr>
          </thead>
          <tbody>
            {leaderboardData.map((row, idx) => (
              <tr key={idx}>
                <td>{row.name}</td>
                <td>{row.bookedRevenue}</td>
                <td>{row.annualQuota}</td>
                <td>{row.activePipeline}</td>
                {/* <td>{row.consultantsOnBilling}</td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default SalesTeamLeaderboard;
